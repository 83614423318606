/**
 * Author: Ismael Esquinca (Adrakiel)
 */
import data0 from "../input/0.json";
import data1 from "../input/1.json";
import data2 from "../input/2.json";
import data3 from "../input/3.json";
import data4 from "../input/4.json";
import data5 from "../input/5.json";
import data7 from "../input/7.json";
import data8 from "../input/8.json";

const _INFO = "INFO";
const _ERROR = "ERROR";

// Function nerv, invoke in debug mode means, all its works fine (Friki humor)
export const nerv = (typeDebug) => {
  switch (typeDebug) {
    case "INFO":
      console.info(`God is in heaven all's right with the world`);
      break;
    case "ERROR":
      console.error(
        `Your truth can be changed simply by the way you accept it. That's how fragile the truth for a human is.`
      );
      break;

    default:
      break;
  }
};

// Function debug, can invoke in any part of code to print data, if debug mode its off do nothing
export const debug = (message) => {
  if (message === "nerv") {
    nerv(_INFO);
    return;
  }

  if (process.env.REACT_APP_DEBUG === "true") {
    console.info(message);
  }
};

// Function debugError, can invoke in any part of code to print data, if debug mode its off do nothing
export const debugError = (message) => {
  if (message === "nerv") {
    nerv(_ERROR);
    return;
  }

  if (process.env.REACT_APP_DEBUG === "true") {
    console.error(message);
  }
};

// Function to format currency into Mexican Pesos
export const formatCurrency = (amount) => {
  const options = {
    style: "currency",
    currency: "MXN",
  };
  const currency = new Intl.NumberFormat("es-MX", options).format(amount);
  return `${currency} MXN`;
};

// Function to convert json from file
export const locationsInput = () => {
  let data = [];

  data.push(data0, data1, data2, data3, data4, data5, data7, data8);
  return data;
};
