import { debug, debugError } from "../utilities/helper";

// Function to extract Total Neiborhoods and, Locality name with most Neiborhoods
export const maxNeighborhoodLocator = (locations) => {
  try {
    let neighborhoods = locations.filter(
      (data) => data.type === "neighborhood"
    );
    let maxCountNeighborhood = 0;
    let localityName = "";
    let totalNeighborhoods = neighborhoods.length;
    let result = {};

    neighborhoods.forEach((neighborhood) => {
        debug(neighborhood)
        neighborhood.context.forEach((contexto) => {
        if (totalNeighborhoods > maxCountNeighborhood) {
          maxCountNeighborhood = totalNeighborhoods;
          localityName = contexto.name;
        }

        if (contexto.type === "locality") {
          result = {
            "name": localityName,
            "totalNeighborhood": maxCountNeighborhood,
          };
        }
      });
    });
    return result;
  } catch (error) {
    debugError(error.message);
    debugError(error.stack);
  }
};
