import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { useEffect, useState } from 'react';

import { fetchGetSuggest } from '../config/fetchUtil';
import { debug, locationsInput } from '../utilities/helper';
import { maxNeighborhoodLocator } from '../controller/neighborhood.controller';
import { proximityLocator } from '../controller/southerGeoLocator.controller';

export const Home = () => {
  type LocalityItem = {
    name?: string,
    totalNeighborhood?: number
  }

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState();

  const [locations, setLocations] = useState([]);

  const [localityName, setLocalityName] = useState('');
  const [maxNeighborhoods, setMaxNeighborhoods] = useState(0);

  const [locationPlaces, setLocationPlaces] = useState([])


  useEffect(() => {
    if (locations.length < 1) {
      setLocations(locationsInput())
      debug('nerv')
    }
    let dataLocality: LocalityItem = maxNeighborhoodLocator(locations);

    debug(dataLocality.name)
    setLocalityName(dataLocality.name);
    setMaxNeighborhoods(dataLocality.totalNeighborhood)

    let dataNerbySoutherPlaces = proximityLocator(locations);

    if (dataNerbySoutherPlaces?.length > 0) {
      setLocationPlaces(dataNerbySoutherPlaces)
    }
    debug(locationPlaces)

  }, [locations, locations.length])

  const handleSearch = (query: string) => {
    setIsLoading(true);
    fetchGetSuggest(query)
      .then((resp) => resp?.suggestions)
      .then((items: any) => {
        setOptions(items);
        setIsLoading(false);
      });
  };

  const filterBy = () => true;

  return (
    <>
      <header className="headerYaoWrapp head_back_c">
        <div className="container">
          <div className="yao_heading">Location Manager</div>
        </div>
      </header>

      <section className="mt-5 mb-3">
        <div className="container">

          <Card>
            <Card.Body>
              <div className="row">
                <div className="col-lg-6">

                  <Card.Text className="escuelas_text">
                    <span>Neighborhood</span>
                    <br></br>
                    <strong>Locality with more neighborhoods: </strong> {localityName}
                    <br></br>
                    <strong>Total of Neighborhoods:</strong> {maxNeighborhoods}
                    <br></br>

                    <br />

                    <span>Southernmost nerby places (50 KM arround</span>
                    <br></br>
                    {
                      locationPlaces.map((item, index) => {
                        return (
                          <>
                          <li key={index}>
                            {item[0]}
                          </li>
                          </>)
                      })
                    }
                    <br />
                  </Card.Text>
                </div>

                <div className="col-lg-6">
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <AsyncTypeahead
                          filterBy={filterBy}
                          id="async-map"
                          isLoading={isLoading}
                          labelKey="name"
                          minLength={3}
                          onSearch={handleSearch}
                          options={options}
                          placeholder="Search for place..."
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </Form>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.943101941302!2d-99.20367850000001!3d19.501083599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2031c91a52bef%3A0x5a76baeb488a5153!2sTONALKALLI!5e0!3m2!1sen!2smx!4v1656525798210!5m2!1sen!2smx" title="frameMap" width="100%" height="600" loading="lazy"></iframe>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>


    </>
  )
}