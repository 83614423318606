import * as geolib from "geolib";
import { debug, debugError } from "../utilities/helper";

// Function to extract Total Neiborhoods and, Locality name with most Neiborhoods
export const proximityLocator = (locations) => {
  try {
    let locationsList = [];
    let locationsContextList = [];
    let centers = [];

    const placeList = locations.filter((loc) => loc.type === "place");
    locationsList.push(placeList);

    locations.forEach((item) => {
      if (item.context) {
        item.context.forEach((contextItem) => {
          if (contextItem.type === "place") {
            locationsList.push(contextItem);
          }
        });
      }
    });

    const allLocations = [...locationsList, ...locationsContextList].flat();

    centers = allLocations.map((loc) => {
      return [loc.name, loc.center];
    });

    let southernmostLocation = null;
    let minLatitude = null;
    let southernmostLocationCenter = null;

    for (let countCenters = 0; countCenters < centers.length; countCenters++) {
      let latitude = parseFloat(centers[countCenters][1].split(":")[0]);

      if (minLatitude === null || latitude < minLatitude) {
        southernmostLocation = centers[countCenters][0];
        southernmostLocationCenter = centers[countCenters][1];
        minLatitude = latitude;
      }
    }

    if("null" !== typeof southernmostLocationCenter){
      debugError(southernmostLocation);
      let southernPlace = southernmostLocationCenter;

      const [lat, lng] = southernPlace.split(":").map(Number);

      const nearbyPlaces = centers.filter(([place, placeLatLng]) => {
        const [placeLat, placeLng] = placeLatLng.split(":").map(Number);
        const distance = geolib.getDistance(
          { latitude: lat, longitude: lng },
          { latitude: placeLat, longitude: placeLng }
        );
        return distance <= 50000;
      });
      debug(nearbyPlaces)

      return nearbyPlaces;
    }

  } catch (error) {
    debugError(error.message);
    debugError(error.stack);
  }
};
