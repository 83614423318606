import { Navbar, Nav, Container } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../assets/img/logo.png';

// import { useState } from "react";
// import { LoginRegister } from "./LoginRegister";

export const Menu = () => {

//  const [show, setShow] = useState(false);
// const handleShow = () => setShow(true);

    return (
    <Navbar expand="lg" className="nav_yao border-bottom" fixed="top">
      <Container>
        <Navbar.Brand href="/">
            <img src={logo} alt="Logo" className="img-fluid" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto"
       
          >
            <Nav.Link href="/">Inicio</Nav.Link>
            
            <Nav.Link href="/"></Nav.Link>
            <Nav.Link href="/"></Nav.Link>
            <Nav.Link href="/"></Nav.Link>
            <Nav.Link href="/"></Nav.Link>
            <Nav.Link href="/"></Nav.Link>

            <NavDropdown title="Conócenos" id="navbarScrollingDropdownd">
              <NavDropdown.Item href="/contacto">Contacto</NavDropdown.Item>
            </NavDropdown>


            {/*
            <Nav.Link onClick={handleShow}>
              <LoginRegister />
            </Nav.Link>
          */}
            

 

          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>

    )
}