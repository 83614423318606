// import { getSecInstance, postSecInstance, putInstance, putSecInstance } from "./axioConfig"
import {
  getInstance,
  postInstance,
  patchInstance,
  deleteInstance,
} from "./axioConfig";
import API from "./constants";
const TOKEN = process.env.REACT_APP_MAP_TOKEN;

// MAPBOX
export const fetchGetSuggest = (search_text) => {
  return getInstance(
    `${API.getSuggest}/${search_text}&access_token=${TOKEN}&session_token=${TOKEN}`
  );
};

// Example
export const fetchTestPost = (input) => {
  return postInstance(`${API.testGeneric}`, input);
};
export const fetchTestPatch = (input) => {
  return patchInstance(`${API.testGeneric}`, input);
};
export const fetchTestDelete = (input) => {
  return deleteInstance(`${API.testGeneric}`, input);
};
