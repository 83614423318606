const CORS = process.env.REACT_APP_CORS;
const BASE_URL = `${CORS}${
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL
    : process.env.PUBLIC_URL
}`;

const MAPBOX = process.env.REACT_APP_MAP_URL;

module.exports = Object.freeze({
  // CONTACT FORM
  testGeneric: `${BASE_URL}/`,

  // SEPOMEX
  getSuggest: `${MAPBOX}/suggest?q=`,
});
