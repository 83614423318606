import { Routes, Route } from 'react-router-dom';
import './App.scss'
import './assets/css/scss/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Menu } from './components/Menu';
import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { Footer } from './components/Footer';

function App() {
  return (
    <>
      <Menu />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='*' element={<NotFound />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;

